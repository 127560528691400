<template>
  <div>
    <Header />
    <Search />
    <div class="ding">
      <div class="dingdan">
        <div class="ding_L">
          <div class="wenzi">
            <div class="wenzi_T">
              <div class="tijiao"> {{ $t('common.shortmessage-002') }}</div>
              <div class="danhao">
                {{ $t('common.Serial_number:') }}{{ istrue == '1' ? orderId : mergeOrderNo }}
              </div>
            </div>
            <div class="wenzi_B">
              请您在尽快<span class="red"></span>完成支付，否则订单会被自动取消
            </div>
          </div>
        </div>
        <div class="ding_R">
          <div class="yingfu">
            {{ $t('common.Amount_Payable:') }}<span class="price">{{ $n( sumPrice , 'currency' ) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="company-info-wrapper">
      <div class="company-info">
        <div class="top">
          <div class="top_l">{{ $t('common.shortmessage-005') }}</div>
          <div class="top-r" @click="fangshi">
            {{ $t('common.shortmessage-003') }}<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="khjl">请联系您的客户经理：{{ khName }}</div>
        <div class="khjl">联系电话：{{ khPhone }}</div>
        <!-- <h4>
          您的汇款识别码
          <span>{{ sign }}</span> 汇款填写备注/摘要栏，便于快速核销款项。
        </h4> -->
        <div v-for="(item, index) in info" :key="index">
          <div class="list" v-if="item.orderStatus == '0'">
            <table cellpadding="0" cellspacing="0">
              <tr>
                <td>{{ $t('label.Beneficiary_account_name') }}</td>
                <td>{{ item.beneficiaryName }}</td>
              </tr>
              <tr>
                <td>{{ $t('label.Bank_card_number') }}</td>
                <td>{{ item.bankCardNumber }}</td>
              </tr>
              <tr>
                <td>{{ $t('label.Bank_of_deposit') }}</td>
                <td>{{ item.depositBank }}</td>
              </tr>
              <tr>
                <td>{{ $t('label.Unionpay_number') }}</td>
                <td>{{ item.unionpayNumber }}</td>
              </tr>
              <tr>
                <td>{{ $t('label.Note/summary_column') }}</td>
                <td>{{ item.purpose }}</td>
              </tr>
              <tr>
                <td>{{ $t('label.Amount_payable') }}</td>
                <td>￥{{ Number(item.orderAmount).toFixed(2) }}</td>
              </tr>
            </table>

            <el-button
              class="button"
              type="primary"
              @click="duigong(item.orderId)"
              >{{ $t('button.Upload_credentials') }}</el-button
            >
          </div>
        </div>
        <div class="warn-info">
          <h4>{{ $t('common.shortmessage-004') }}</h4>
          <p>
            1、{{ $t('common.longmessage-002') }}
          </p>
          <p>2、{{ $t('common.longmessage-003') }}</p>
        </div>
      </div>
    </div>

    <el-dialog
      :title="$t('common.shortmessage-005')"
      :visible.sync="dialogVisible1"
      width="40%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="140px" :rules="rules">
        <!--        <el-form-item label="交易编号" prop="payRemittanceId">-->
        <!--          <el-input v-model="form.payRemittanceId"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item :label="$t('label.Trading_hours')" prop="paymentTime">
          <el-date-picker
            v-model="form.paymentTime"
            type="datetime"
            :placeholder="$t('placeholder.Select_trading_time')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('label.Name_of_payer')" prop="payerName">
          <el-input v-model="form.payerName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.Payment_account_number')" prop="payerCardNumber">
          <el-input v-model="form.payerCardNumber"></el-input>
        </el-form-item>
        <!--        <el-form-item label="付款摘要" prop="paymentSummary">-->
        <!--          <el-input v-model="form.paymentSummary"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item :label="$t('message.shortmessage-027')">
          <el-upload
            class="avatar-uploader"
            :action="picurl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
          >
            <Uimage v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">{{ $t('button.Cancel') }}</el-button>
        <el-button type="primary" @click="confirm('form')"> {{ $t('button.Confirm') }}</el-button>
      </span>
    </el-dialog>

    <!-- <Baoyou/> -->
    <div class="kongbai"></div>
    <Foot />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import { getPayCompanyInfo } from '@/api/company';
import { get, post } from '@/utils/request';
import Foot from '@/components/foot.vue';
import Search from '@/components/order/buy_succeed/search.vue';
import Baoyou from '@/components/order/baoyou.vue';
import { baseURL } from '@/utils/request';
import QRCode from 'qrcodejs2';
export default {
  components: {
    Header,
    Foot,
    Search,
    Baoyou,
  },
  data() {
    return {
      khName: '',
      khPhone: '',
      mergeOrderNo: null,
      sumPrice: 0,
      dialogVisible1: false,
      picurl: baseURL + 'f/api/frontCommon/payVoucher',
      form: {
        payRemittanceId: '',
        payerName: '',
        payerCardNumber: '',
        paymentSummary: '',
        imageUrl: '',
        paymentTime: '',
      },
      coverImg: '',
      sign: '',
      info: [],
      orderId: '',
      istrue: '2',
      mergeOrderNo: '',
      itemId: '',
      rules: {
        // payRemittanceId: [
        //   { required: true, message: '请输入交易编号', trigger: 'blur' },
        //   {
        //     min: 1,
        //     max: 100,
        //     message: '长度在 1 到 100 个字符',
        //     trigger: 'blur',
        //   },
        // ],
        payerName: [
          { required: true, message: this.$t('message.shortmessage-006'), trigger: 'blur' },
          {
            min: 1,
            max: 100,
            message: this.$t('message.shortmessage-008'),
            trigger: 'blur',
          },
        ],
        payerCardNumber: [
          { required: true, message: this.$t('message.shortmessage-007'), trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: this.$t('message.shortmessage-009'),
            trigger: 'blur',
          },
        ],
        // paymentSummary: [
        //   { required: true, message: '请输入付款摘要', trigger: 'blur' },
        //   {
        //     min: 1,
        //     max: 100,
        //     message: '长度在 1 到 100 个字符',
        //     trigger: 'blur',
        //   },
        // ],

        paymentTime: [
          { required: true, message: this.$t('message.shortmessage-010'), trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.istrue = this.$route.query.istrue;
    this.orderId = this.$route.query.orderId;
    this.sumPrice = this.$route.params.price;
    this.getdetail(this.$route.query.orderId);
    this.getmanager();
  },
  watch: {
    info() {
      if (this.info.findIndex((target) => target.orderStatus == '0') == -1) {
        this.$router.push({
          name: 'Buy_succeed',
        });
      }
    },
  },
  methods: {
    getmanager() {
      get('api/account/getMyManagerInfo').then((res) => {
        this.khName = res.data.data.memberName;
        this.khPhone = res.data.data.phone;
      });
    },
    fangshi() {
      this.$router.push({
        name: 'Zhuanzhang',
        params: {
          price: this.sumPrice,
        },
        query: {
          orderId: this.orderId,
          istrue: this.istrue,
        },
      });
    },

    getdetail(e) {
      if (this.istrue == '1') {
        this.getduigong();
      } else {
        get('api/orderbasic/getorderbyid?orderId=' + e + '').then((res) => {
          this.mergeOrderNo = res.data.data.orderNo;
          this.getduigong();
        });
      }
    },

    getduigong() {
      if (this.istrue == '1') {
        get(
          'api/orderbasic/getOrderByMergeOrderNo?mergeOrderNo=' +
            this.orderId +
            '&isTrue=1'
        ).then((res) => {
          get('f/api/settlement/customInfo?dictType=biz_bank').then((rew) => {
            res.data.data.forEach((i) => {
              rew.data.data.forEach((j) => {
                if (i.depositBank == j.dictValue) {
                  i.depositBank = j.dictLabel;
                }
              });
            });
          });
          this.info = res.data.data;
        });
      } else {
        get(
          'api/orderbasic/getOrderByMergeOrderNo?mergeOrderNo=' +
            this.mergeOrderNo +
            '&isTrue=2'
        ).then((res) => {
          get('f/api/settlement/customInfo?dictType=biz_bank').then((rew) => {
            res.data.data.forEach((i) => {
              rew.data.data.forEach((j) => {
                if (i.depositBank == j.dictValue) {
                  i.depositBank = j.dictLabel;
                }
              });
            });
          });
          this.info = res.data.data;
        });
      }
    },

    getorderbyid(e) {
      if (this.istrue == '1') {
        this.getdetail();
      } else {
        get('api/orderbasic/getorderbyid?orderId=' + e + '').then((res) => {
          this.mergeOrderNo = res.data.data.orderNo;
          this.getdetail();
        });
      }
    },

    duigong(e) {
      this.itemId = e;
      // 初始化默认发票信息
      // for (var i=0;i<this.info.length;i++){
      //   if (this.info[i].orderId==e){
      //     this.form.payerName=this.info[i].invTitle;
      //     this.form.payerCardNumber=this.info[i].bankAccount;
      //   }
      // }
      this.dialogVisible1 = true;
    },
    confirm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          post('api/orderbasic/updatePayVoucher', {
            paymentVoucher: this.coverImg,
            orderId: this.itemId,
            paymentAmount: this.sumPrice,
            // payRemittanceId: this.form.payRemittanceId,
            payerName: this.form.payerName,
            payerCardNumber: this.form.payerCardNumber,
            // paymentSummary: this.form.paymentSummary,
            paymentTime: this.form.paymentTime,
          }).then((res) => {
            if (res.data.code == 0) {
              // (this.form.payRemittanceId = ''),
              (this.form.payerName = ''),
                (this.form.payerCardNumber = ''),
                // (this.form.paymentSummary = ''),
                (this.form.imageUrl = ''),
                (this.form.paymentTime = ''),
                (this.dialogVisible1 = false);
              this.getdetail(this.orderId);
            } else {
              this.$message.error({
                message: res.data.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm(this.$t('message.Confirm_closure'))
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleAvatarSuccess(res, file) {
      this.coverImg = res.fileName;
      this.form.imageUrl = res.fileName;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        'image/jpg' ||
        'image/webp' ||
        'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error({
          message: this.$t('message.shortmessage-011'),
        });
      }
      if (!isLt2M) {
        this.$message.error({
          message: this.$t('message.shortmessage-012'),
        });
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="scss" scoped>
.khjl {
  margin-top: 10px;
  color: #999;
}
.list {
  border-bottom: 1px solid #666;
  padding-bottom: 20px;
}
table {
  margin-top: 20px;
}
.button {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 400px;
  height: 200px;
}
.company-info-wrapper {
  background-color: #f5f5f5;
}

.company-info {
  width: 900px;
  padding: 20px 40px;
  margin: 0 auto;
  background-color: #fff;
  .top {
    display: flex;
    justify-content: space-between;
    .top_l {
      font-size: 20px;
    }
    .top-r {
      cursor: pointer;
    }
  }
}

.company-info h4 {
  margin: 10px 0 30px 0;
  color: #666;
  font-size: 16px;
  font-weight: normal;
}

.company-info h4 span {
  color: #1950ff;
  font-weight: bold;
}

.company-info table {
  font-size: 16px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  width: 100%;
}

.company-info table tr td:first-child {
  font-weight: bold;
}

.company-info table tr:first-child {
  background-color: #f6f6f6;
}

.company-info table td {
  padding: 10px 20px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.warn-info h4 {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  margin: 20px 0 10px 0;
}
.warn-info p {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
}
</style>

<style lang="scss" scoped>
.ding {
  width: 100%;
  background: #f5f5f5;

  .dingdan {
    width: 1240px;
    height: 123px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ding_L {
      display: flex;

      .erweima {
        width: 95px;
        height: 90px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .wenzi {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;

        .wenzi_T {
          font-size: 16px;
          display: flex;

          .tijiao {
            color: #3e3e3e;
          }

          .danhao {
            color: #282828;
          }
        }

        .wenzi_B {
          font-size: 14px;
          margin-top: 15px;
          color: #3f3f3f;

          .red {
            color: #e41412;
          }
        }
      }
    }

    .ding_R {
      display: flex;
      flex-direction: column;

      .yingfu {
        color: #333;
        font-size: 16px;

        .price {
          font-size: 16px;
          color: #e53739;
        }
      }

      .detail {
        font-size: 14px;
        color: #1b50fe;
        margin-top: 15px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
